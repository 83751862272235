import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";

import { CatalogApi } from "@/services/api";

const catalogApi: CatalogApi = new CatalogApi();


@Module({
  name: "CatalogState",
  namespaced: true
})
export class CatalogState extends VuexModule {

  private products_: any[] = [];
  private product_: any = null;

  get products(): any[] {
    return this.products_;
  }

  get product(): any {
    return this.product_;
  }

  @Action({
    commit: "saveProducts"
  })
  async getProducts(payload: any): Promise<any> {
    const result: any = await catalogApi.getList(payload);
    return result;
  }

  @Action({
    commit: "saveProduct"
  })
  async getProduct(payload: any): Promise<any> {
    const result: any = await catalogApi.getById(payload);
    return result;
  }

  @Mutation
  saveProducts(payload: { status: number, data: any }): void {
    if (!payload.data) return;
    this.products_ = payload.data.values || [];
  }

  @Mutation
  saveProduct(payload: { status: number, data: any }): void {
    if (!payload.data) return;
    this.product_ = payload.data || null;
  }

}
