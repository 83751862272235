







import Vue from "vue";

import Component from "vue-class-component";

@Component({
  name: "IconCheckCircleS",
  props: {
    fill: {
      type: String,
      default: "var(--color-success)"
    }
  },
})
export default class IconCheckCircleS extends Vue {
}
