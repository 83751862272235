/* eslint no-shadow: ["error", { "allow": ["state"] }] */

const state = {
  show_: null,
};

const mutations = {
  updateShow(state, value) {
    state.show_ = value;
  },
};

const getters = {
  show: (state) => state.show_,
};

const actions = {
  setShow({ commit }, payload) {
    commit('updateShow', payload);
  },
};

export default {
  name: 'DrawerState',
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
