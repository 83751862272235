






import Vue from "vue";

import Component from "vue-class-component";

@Component({
  name: "BaseComponent"
})
export default class BaseComponent extends Vue {

  /**
   * Initial loading flag.
   */
  protected initLoading: boolean = false;
  
  /**
   * Loading flag.
   */
  protected loading: boolean = false;

  /**
   * Calculated property for current language string.
   */
  get lang(): string {
    return this.$vuetify.lang.current;
  }

  /**
   * Calculated property for small screen flag.
   */
  get smOnly(): boolean {
    return this.$vuetify.breakpoint.smOnly;
  }

  /**
   * Calculated property for medium screen flag.
   */
  get mdOnly(): boolean {
    return this.$vuetify.breakpoint.mdOnly;
  }

  /**
   * Calculated property for large screen flag.
   */
  get lgOnly(): boolean {
    return this.$vuetify.breakpoint.lgOnly;
  }

  /**
   * Calculated property for extra large screen flag.
   */
  get xlOnly(): boolean {
    return this.$vuetify.breakpoint.xlOnly;
  }

  /**
   * Scroll current page to top.
   */
  public backToTop(): void {
    this.$vuetify.goTo(0, {
        duration: 0,
        offset: 0,
        easing: 'linear',
      }
    );
  }

  /**
   * Display error message.
   */
  public displayError(message: string): void {
    this.$store.dispatch('ErrorsState/addError', { err: new Error(message), info: "" });
  }

}
