export const UserbackColors = [
  {
    id: "MySport",
    backgroundColour: "#FF3300",
    textColour: "#FFFFFF",
  },
  { 
    id: "IronStar",
    backgroundColour: "#9A1915",
    textColour: "#FFFFFF",
  },
  {
    id: "TimerMan",
    backgroundColour: "#C81426",
    textColour: "#FFFFFF",
  },
];
