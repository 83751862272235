import axios, { AxiosInstance } from "axios";


export class ArchiveApi {
  
  protected readonly api: AxiosInstance = axios.create({
    responseType: "json",
    baseURL: "/public/archive-service/api/v1",
    headers: {
      "Content-Type": "application/json"
    }
  });

  public async createArchive(payload: any[]): 
    Promise<{ status: number, data: any }> {
    
    const result: { status: number, data: any } = await this.api
      .post<any>(`/archives/`, payload)
      .then(response => {
        return { status: response.status, data: response.headers };
      })
      .catch(error => {
        if (error.response === undefined) {
          // on network error
          return { status: 1001, data: null };
        } else {
          // on other errors
          return { status: error.response.status, data: null };
        }
      });
    
    return result;
  }

  public async getArchive(payload: { archiveId: string }): 
    Promise<{ status: number, data: any }> {

    const result: { status: number, data: any } = await this.api
      .get<any>(`/archives/${payload.archiveId}`)
      .then(response => {
        return { status: response.status, data: response.data };
      })
      .catch(error => {
        if (error.response === undefined) {
          // on network error
          return { status: 1001, data: null };
        } else {
          // on other errors
          return { status: error.response.status, data: null };
        }
      });
    
    return result;
  }

  public async downloadFile(payload: { url: string }): 
    Promise<{ status: number, data: any }> {

    const api: AxiosInstance = axios.create({
      responseType: "blob",
      headers: {
        "Cache-Control": "no-cache",
        "Pragma": "no-cache",
      }
    });
        
    const result: { status: number, data: any } = await api
      .get<any>(payload.url)
      .then(response => {
        return { status: response.status, data: response.data };
      })
      .catch(error => {
        if (error.response === undefined) {
          // on network error
          return { status: 1001, data: null };
        } else {
          // on other errors
          return { status: error.response.status, data: null };
        }
      });
    
    return result;
  }

}
