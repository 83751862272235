import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";

import { KktApi } from "@/services/api";

const kktApi: KktApi = new KktApi();


@Module({
  name: "KktState",
  namespaced: true
})
export class KktState extends VuexModule {

  private receipts_: any = null;
  private receipt_: any = null;
  private receiptFile_: any = null;
  private status_: number = 0;

  get receipts(): any {
    return this.receipts_;
  }

  get receipt(): any {
    return this.receipt_;
  }

  get receiptFile(): any {
    return this.receiptFile_;
  }

  get status(): number {
    return this.status_;
  }

  get authToken(): any {
    return this.context.rootGetters["SettingsState/authToken"];
  }

  @Action({
    commit: "saveReceipts"
  })
  async getReceipts(payload: any): Promise<any> {
    const token: any = this.context.getters["authToken"];
    if (token) payload['token'] = token;
    return await kktApi.getReceipts(payload);
  }

  @Action({
    commit: "saveReceipt"
  })
  async getReceiptById(payload: any): Promise<any> {
    const token: any = this.context.getters["authToken"];
    if (token) payload['token'] = token;
    return await kktApi.getReceiptById(payload);
  }

  @Action({
    commit: "saveFile"
  })
  async downloadReceipts(payload: any): Promise<any> {
    const token: any = this.context.getters["authToken"];
    if (token) payload['token'] = token;
    return await kktApi.downloadReceipts(payload);
  }

  @Mutation
  saveReceipts(payload: { status: number, data: any }): void {
    this.status_ = payload.status;

    if (payload.status !== 200) {
      this.receipts_ = null;
    } else {
      this.receipts_ = payload.data;
    }
  }

  @Mutation
  saveReceipt(payload: { status: number, data: any }): void {
    this.status_ = payload.status;

    if (payload.status !== 200) {
      this.receipt_ = null;
    } else {
      this.receipt_ = payload.data;
    }
  }

  @Mutation
  saveFile(payload: { status: number, data: any }): void {
    this.status_ = payload.status;

    if (payload.status !== 200) {
      this.receiptFile_ = null;
    } else {
      this.receiptFile_ = payload.data;
    }
  }

  @Mutation
  saveStatus(payload: { status: number, data: any }): void {
    this.status_ = payload.status;
  }

}
