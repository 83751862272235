export const MenuMain = [
  { 
    id: 1, 
    text: "appBar/menuCompetitors", 
    href: "/",
  },
  { 
    id: 2, 
    text: "appBar/menuPhotographers", 
    href: "/for-photographers",
    hideFor: ['MyEventPhoto'],
  },
  { 
    id: 3, 
    text: "appBar/menuOrganizers", 
    href: "/for-organizers",
    hideFor: ['MyEventPhoto'],
  },
  { 
    id: 4, 
    text: "appBar/menuBusiness", 
    href: "/for-business",
    hideFor: ['MyEventPhoto'],
  },
  { 
    id: 5, 
    href: "divider",
    showWithKeycloak: true,
  },
  { 
    id: 6, 
    text: "appBar/account", 
    href: "/my-account",
    icon: "icon-user",
    showWithKeycloak: true,
  },
  { 
    id: 7, 
    href: "divider",
  },
  { 
    id: 8, 
    text: "appBar/menuContacts", 
    href: "/contacts",
  },
];