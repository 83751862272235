import axios, { AxiosInstance } from "axios";


export class RecognitionApi {
  
  protected readonly api: AxiosInstance = axios.create({
    responseType: "json",
    baseURL: "/public/recognition-service/api/v1",
    headers: {
      "Content-Type": "application/json"
    }
  });

  public async recognize(payload: { file: File, albumId: string }): Promise<{ status: number, data: any }> {
    
    const form = new FormData();
    form.append("file", payload.file, "face.jpg");    

    const result: { status: number, data: any } = await this.api
      .post<any>(`/selfies?albumId=${payload.albumId}`, form, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(response => {
        return { status: response.status, data: response.data };
      })
      .catch(error => {
        if (error.response === undefined) {
          // on network error
          return { status: 1001, data: null };
        } else {
          // on other errors
          return { status: error.response.status, data: null };
        }
      });
    
    return result;
  }

  public async loadBySelfieId(payload: { selfieId: string }): Promise<{ status: number, data: any }> {
    
    const result: { status: number, data: any } = await this.api
      .get<any>(`/selfies/${payload.selfieId}`)
      .then(response => {
        return { status: response.status, data: response.data };
      })
      .catch(error => {
        if (error.response === undefined) {
          // on network error
          return { status: 1001, data: null };
        } else {
          // on other errors
          return { status: error.response.status, data: null };
        }
      });
    
    return result;
  }

}
