<template>
  <v-navigation-drawer 
    width="296"
    v-model="drawer"
    app 
  >
    
    <div class="menu__header" @click.stop="onLangBlur()">
      <a href="/" class="header__logo">
        <img :src="logo" alt="" style="max-width: 100%;">
      </a>
      <Button 
        @click="drawer = false"
        variant="ghost"
        view="light"
        shape="circle"
        size="lg"
        class="header__close" 
      >
        <slot name="before">
          <span class="rr-button__icon">
            <base-icon v-if="theme === ''" width="20" height="20" viewBox="0 0 24 24">
              <icon-close2 fill="var(--color-black)" />
            </base-icon>
            <base-icon v-if="theme !== ''" width="20" height="20" viewBox="0 0 20 20">
              <icon-close fill="rgb(0, 0, 0)" opacity="1" />
            </base-icon>
          </span>
        </slot>
      </Button>

    </div>

    <div class="menu__body" @click.stop="onLangBlur()">
      <div
        v-for="item in menuItems" :key="item.id"
        class="menu__item"
      >
        <div v-if="isDivider(item) && isShownWithKeycloak(item)" class="item__divider"></div>
        <a v-if="!isDivider(item) && isItemVisible(item)" href="#" @click.prevent="onMenuItem(item)" class="menu__link">
          <span class="link__icon">
            <base-icon width="18" height="18" :viewBox="item.icon ? '0 0 24 24' : '0 0 18 18'">
              <component :is="item.icon ? item.icon : 'icon-caret-right'" />
            </base-icon>
          </span>
          <span>{{ $t(item.text) }}</span>
        </a>
      </div>
      <div v-if="false && !isMySportUSA" class="menu__item">
        <div class="item__divider"></div>
      </div>
      <div v-if="false && !isMySportUSA" class="menu__item">
        <a href="#" @click.prevent="onReportBug()" class="menu__link">
          <span class="link__icon">
            <base-icon width="18" height="18">
              <icon-bug />
            </base-icon>
          </span>
          <span>{{ $t('appBar/menuReportError') }}</span>
        </a>
      </div>
      <div class="menu__item" v-if="$store.state.showLang && !isMySportUSA && menuLang.length > 1">
        <div class="item__divider"></div>
      </div>
      <div class="menu__item" v-if="$store.state.showLang && !isMySportUSA && menuLang.length > 1">
        <Button 
          @click.stop="onLangClick()"
          @blur="onLangBlur()"
          variant="secondary" 
          size="md"
          class="item__lang-button"
          :class="!$root.isMobile ? 'item__lang-button--large' : ''"
        >
          <slot name="before">
            <span class="rr-button__icon">
              <base-icon width="18" height="18" class="lang-button__icon">
                <icon-global fill="currentColor" />
              </base-icon>
            </span>
          </slot>
          <slot>
            <span class="rr-button__text">{{ langTitle }}</span>
            <div v-if="$store.state.showLang && !isMySportUSA">
              <a href="#" class="lang__menu-item">
                <div class="menu-item__submenu" :style="{'display': showLangDropdown ? 'block' : 'none'}">
                  <div
                    v-for="item in menuLang"
                    :key="item.id"
                    class="submenu__item"
                    @click.prevent="onLangSelect(item.id)"
                  >
                    <div class="submenu__image">
                      <img :src="'/img/lang/' + item.id + '.svg'" :alt="item.id" />
                    </div>
                    <div class="submenu__text" @click.prevent="onLangSelect(item.id)">
                      {{ item.text }}
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </slot>
        </Button>
      </div>
    </div>

  </v-navigation-drawer>
</template>


<script lang="js">
import { mapGetters } from 'vuex';
import BaseIcon from '@/components/base-icon/base-icon.vue';
import IconClose from '@/components/icons/icon-close.vue';
import IconClose2 from '@/components/icons/icon-close2.vue';
import IconCaretRight from '@/components/icons/icon-caret-right.vue';
import IconBug from '@/components/icons/icon-bug.vue';
import IconGlobal from '@/components/icons/icon-global.vue';
import IconUser from '@/components/icons/icon-user.vue';
import { MenuMain } from '@/menu-main';
import { MenuLang } from '@/menu-lang';

export default {
  name: "NavigationDrawer",
  components: {
    BaseIcon,
    IconClose,
    IconClose2,
    IconCaretRight,
    IconBug,
    IconGlobal,
    IconUser,
  },
  data() {
    return {
      menuItems: MenuMain,
      menuLang: MenuLang,
      langTitle_: '',
      showLangDropdown: false,
    };
  },
  computed: {
    ...mapGetters('SettingsState', ['theme', 'lang', 'isMySportUSA', 'keycloak', 'languages', 'logoSmall']),
    ...mapGetters('DrawerState', ['show']),
    drawer: {
      get: function() {
        return this.show;
      },
      set: function(value) {
        this.$store.dispatch('DrawerState/setShow', value);
        if (value === false) {
          this.showLangDropdown = false;
        }
      },
    },
    langTitle() {
      return this.langTitle_;
    },
    logo() {
      return this.logoSmall || '/img/logo-media-sm.svg';
    },
  },
  watch: {
    lang() {
      this.onLangChanged();
    },
  },
  methods: {
    onReportBug() {
      if (window.Userback) {
        window.Userback.open();
      }

      this.drawer = false;

      return false;
    },
    onLangChanged() {
      const found = this.menuLang.filter((i) => i.id === this.lang);
      if (found.length == 0) this.langTitle_ = '';
      this.langTitle_ = found[0].text;
    },
    onLangClick() {
      const vm = this;
      setTimeout(() => { vm.showLangDropdown = !vm.showLangDropdown; return false; }, 250);
    },
    onLangBlur() {
      const vm = this;
      setTimeout(() => { vm.showLangDropdown = false; }, 250);
    },
    onLangSelect(lang) {
      if (!this.$root.isMobile && !this.$root.isTablet) {
        this.showLangDropdown = false;    
        setTimeout(() => { this.showLangDropdown = true; }, 100);
      }
      
      this.setLang(lang);
    },
    onMenuItem(item) {
      window.scrollTo(0, 0);
      this.$router.replace({ path: item.href });
      this.drawer = false;
    },
    isItemVisible(item) {
      if (item.href !== '/' && item.href !== '/contacts' && this.isMySportUSA) {
        return false;
      }
      if (!this.isShownWithKeycloak(item)) return false;
      return true;
    },
    isDivider(item) {
      return item.href === 'divider';
    },
    isShownWithKeycloak(item) {
      if (item.showWithKeycloak === true && !this.keycloak) {
        return false;
      }
      return true;
    },
    setMenu() {
      const menu = MenuMain.filter((i) => !i.hideFor || (i.hideFor && !i.hideFor.includes(this.theme)));
      this.menuItems = menu;
    },
    setLang(payload) {
      this.$store.dispatch('SettingsState/setLang', payload);
    },
    setLanguagesAvailable() {
      const langs = this.languages;
      if (langs) {
        const result = [];
        for (let i = 0; i < MenuLang.length; i += 1) {
          if (langs.includes(MenuLang[i].id)) {
            result.push(MenuLang[i]);
          }
        }
        this.menuLang = result;
        return;
      }
      this.menuLang = MenuLang;
    },
  },
  mounted() {
    this.setMenu();
    this.setLanguagesAvailable();
    this.onLangChanged();
  },
};
</script>


<style lang="scss" scoped>
  @import "./navigation-drawer";
</style>
