import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";

import { PhotoServiceApi } from "@/services/api";

const photoServiceApi: PhotoServiceApi = new PhotoServiceApi();


@Module({
  name: "EventsState",
  namespaced: true
})
export class EventsState extends VuexModule {

  private events_: any[] = [];
  private eventsTotal_: number = 0;
  private event_: any = null;
  private status_: number = 0;

  get responseStatus(): number {
    return this.status_;
  }

  get events(): any[] {
    return this.events_;
  }

  get eventsTotal(): number {
    return this.eventsTotal_;
  }

  get event(): any {
    return this.event_;
  }

  @Action({
    commit: "saveEvents"
  })
  async getEvents(payload: { search: string, pagination: any, organizerId?: string }): Promise<any> {
    const data = {
      ...payload,
      lang: this.context.rootGetters["SettingsState/lang"],
    };
    return await photoServiceApi.getEvents(data);
  }

  @Action
  async getEventById(eventId: string): Promise<any> {
    const result: any = await photoServiceApi.getEventById(eventId, this.context.rootGetters["SettingsState/lang"]);
    const payload: any = {
      status: result.status,
      data: Object.assign({}, result.data),
    };
    if (payload.data) payload.data["eventId"] = eventId;
    this.context.commit("saveEvent", payload);
  }

  @Action
  async getEventByExternalId(externalId: string): Promise<any> {
    const result: any = await photoServiceApi.getEvents({ 
      externalEventId: externalId,
      organizerId: this.context.rootGetters["SettingsState/organizerId"],
      lang: this.context.rootGetters["SettingsState/lang"],
    });
    const payload: any = {
      status: result.status,
      data: Object.assign({}, result.data),
    };
    
    if (payload.data && payload.data.values) {
      payload.data = payload.data.values[0] || null;
    } else {
      payload.data = null;
    }
    this.context.commit("saveEvent", payload);
  }

  @Mutation
  saveEvents(payload: { status: number, data: any }): void {
    this.status_ = payload.status;

    if (!payload.data || !payload.data.values) {
      this.events_ = [];
      this.eventsTotal_ = 0;
      return;
    }
    this.events_ = payload.data.values;
    this.eventsTotal_ = payload.data.total;
  }

  @Mutation
  saveEvent(payload: { status: number, data: any }): void {
    this.status_ = payload.status;

    if (!payload.data) {
      this.event_ = null;
      return;
    }
    this.event_ = payload.data;
  }

}
