import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";

import { ThemesApi } from "@/services/api";
import YandexMetrikaId from "@/yandex.metrika";

const themesApi: ThemesApi = new ThemesApi();

const DEBUG_AUTH_TOKEN: string = ``;

declare global {
  const MYSPORT_PHOTO_APP_SETTINGS: any;
  const ym: any;
}

@Module({
  name: "SettingsState",
  namespaced: true
})
export class SettingsState extends VuexModule {

  private settings_: any = null;
  private lang_: string = "ru";
  private themeImages_: any[] = [];
  private isAppBarHidden_: boolean = false;
  private isModalActive_: boolean = false;
  private isLoadingEvents_: boolean = false;
  private isLoadingRoute_: boolean = false;
  private isUsedDefaultLang_: boolean = false;
  private myPhotosLink_: string = "";

  get lang(): string {
    return this.lang_;
  }

  get settings(): any {
    return this.settings_;
  }

  get theme(): any {
    return this.settings_ ? this.settings_.theme : null;
  }

  get organizerId(): any {
    return this.settings_ ? this.settings_.organizerId : null;
  }

  get tenantName(): any {
    return this.settings_ ? this.settings_.tenantName : null;
  }

  get openId(): any {
    return this.settings_ ? this.settings_.openId : null;
  }

  get logo(): any {
    return this.settings_ ? this.settings_.logo : null;
  }

  get logoSmall(): any {
    return this.settings_ ? this.settings_.logoSmall : null;
  }

  get backURL(): any {
    return this.settings_ ? this.settings_.backURL : null;
  }

  get languages(): any {
    return this.settings_ ? this.settings_.languages : null;
  }

  get isMySportUSA(): any {
    return this.settings_ ? this.settings_.mySportUSA : null;
  }

  get backFromHome(): any {
    return this.settings_ ? this.settings_.backFromHome : null;
  }

  get hideBannerOnHome(): any {
    return this.settings_ ? this.settings_.hideBannerOnHome : null;
  }

  get useAppBarDefault(): any {
    return this.settings_ ? this.settings_.useAppBarDefault : null;
  }

  get keycloak(): any {
    return this.settings_ ? this.settings_.keycloak : null;
  }

  get themeDone(): boolean {
    return (this.theme !== null && this.organizerId !== null && this.isMySportUSA !== null);
  }

  get themeImages(): any[] {
    return this.themeImages_;
  }

  get isAppBarHidden(): boolean {
    return this.isAppBarHidden_;
  }

  get isModalActive(): boolean {
    return this.isModalActive_;
  }

  get isLoadingEvents(): boolean {
    return this.isLoadingEvents_;
  }

  get isLoadingRoute(): boolean {
    return this.isLoadingRoute_;
  }

  get isUsedDefaultLang(): boolean {
    return this.isUsedDefaultLang_;
  }

  get isAuthenticated(): boolean {
    if (DEBUG_AUTH_TOKEN) return true;
    return this.keycloak ? this.keycloak.authenticated === true : false;
  }

  get authToken(): any {
    if (DEBUG_AUTH_TOKEN) return DEBUG_AUTH_TOKEN;
    return this.keycloak ? this.keycloak.token : null;
  }

  get tokenParsed(): any {
    return this.keycloak ? this.keycloak.tokenParsed : null;
  }

  get email(): any {
    return this.tokenParsed ? this.tokenParsed.email : null;
  }

  get myPhotosLink(): string {
    return this.myPhotosLink_;
  }

  @Action({
    commit: "saveSettings"
  })
  async loadSettings(): Promise<any> {
    return MYSPORT_PHOTO_APP_SETTINGS;
  }

  @Action({
    commit: "saveLang"
  })
  async loadLang(): Promise<any> {
    return localStorage.getItem("appLanguage");
  }

  @Action({
    commit: "saveLang"
  })
  async setLang(lang: string): Promise<any> {
    localStorage.setItem("appLanguage", lang);
    MYSPORT_PHOTO_APP_SETTINGS["lang"] = lang;
    return lang;
  }

  @Action({
    commit: "saveImages"
  })
  async loadImages(themeId: string): Promise<any> {
    const result: any = await themesApi.getThemeImages(themeId, this.context.getters["lang"]);
    return result;
  }

  @Action({
    commit: "saveAppBarHidden"
  })
  async setAppBarHidden(hidden: boolean): Promise<any> {
    return hidden;
  }

  @Action({
    commit: "saveModalActive"
  })
  async setModalActive(active: boolean): Promise<any> {
    return active;
  }

  @Action({
    commit: "saveLoadingEvents"
  })
  async setLoadingEvents(loading: boolean): Promise<any> {
    return loading;
  }

  @Action({
    commit: "saveLoadingRoute"
  })
  async setLoadingRoute(loading: boolean): Promise<any> {
    return loading;
  }

  @Action({
    commit: "saveMyPhotosLink"
  })
  async setMyPhotosLink(link: string): Promise<any> {
    return link;
  }

  /**
   * Metrika: Track change of page address.
   */
  @Action
  async trackPage(page: string): Promise<void> {
    if (process.env.NODE_ENV === 'production') {
      ym(YandexMetrikaId, 'hit', page);
    }
  }

  /**
   * Metrika: Track reach of goal.
   */
  @Action
  async reachGoal(target: string): Promise<void> {
    if (process.env.NODE_ENV === 'production') {
      ym(YandexMetrikaId, "reachGoal", target);
    }
  }

  /**
   * Metrika: set user params and ID.
   */
  @Action
  async setUser(email: string): Promise<void> {
    if (process.env.NODE_ENV === "production") {
      ym(YandexMetrikaId, "userParams", { email: email, UserID: email });
      ym(YandexMetrikaId, "setUserID", email);
    }
  }

  @Mutation
  saveSettings(settings: any): void {
    if (settings) {
      this.settings_ = settings;
    }
  }
  
  @Mutation
  saveLang(lang: any): void {
    if (lang) {
      this.lang_ = lang;
    } else {
      this.isUsedDefaultLang_ = true;
    }
  }

  @Mutation
  saveImages(payload: { status: number, data: any }): void {
    if (payload.data) {
      this.themeImages_ = payload.data.values || [];
    }
  }

  @Mutation
  saveAppBarHidden(value: boolean): void {
    this.isAppBarHidden_ = value;
  }

  @Mutation
  saveModalActive(value: boolean): void {
    this.isModalActive_ = value;
  }

  @Mutation
  saveLoadingEvents(loading: boolean): void {
    this.isLoadingEvents_ = loading;
  }

  @Mutation
  saveLoadingRoute(loading: boolean): void {
    this.isLoadingRoute_ = loading;
  }

  @Mutation
  saveMyPhotosLink(link: string): void {
    this.myPhotosLink_ = link;
  }
}
