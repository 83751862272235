import axios, { AxiosInstance } from "axios";


export class PreorderApi {
  
  protected readonly api: AxiosInstance = axios.create({
    responseType: "json",
    baseURL: "/public/preorder-service/api/v1",
    headers: {
      "Content-Type": "application/json"
    }
  });

  public async getPreorderDetails(payload: { preorderId: string }): 
    Promise<{ status: number, data: any }> {
    
    const result: { status: number, data: any } = await this.api
      .get<any>(`/preorders/${ payload.preorderId }/details`)
      .then(response => {
        return { status: response.status, data: response.data };
      })
      .catch(error => {
        if (error.response === undefined) {
          // on network error
          return { status: 1001, data: null };
        } else {
          // on other errors
          return { status: error.response.status, data: null };
        }
      });
    
    return result;
  }

}
