







import Vue from "vue";

import Component from "vue-class-component";

@Component({
  name: "IconAppStore",
  props: {
    fill: {
      type: String,
      default: "var(--color-black-45)"
    },
  }
})
export default class IconAppStore extends Vue {
}
