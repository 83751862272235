<template>
  <div class="rr-notification" v-show="isOpen">
    <div class="rr-notification__container">
      <div class="rr-notification__close">
        <button
          :class="`${btnPrefix}button ${btnPrefix}button_square ${btnPrefix}button_sm ${btnPrefix}button_clear`"
          @click.stop="dismiss"
        >
          <span :class="`${btnPrefix}button__icon`">
            <icon name="close"></icon>
          </span>
        </button>
      </div>
      <div class="rr-notification__body">
        <div class="rr-notification__img" v-if="img">
          <img :src="img" alt="">
        </div>
        <div class="rr-notification__discount" v-if="discountText">
          <span v-html="discountText"></span>
        </div>
        <div class="rr-notification__content">
          <div class="rr-notification__title" v-html="title"></div>
          <div class="rr-notification__text" v-html="text"></div>
        </div>
      </div>
      <div class="rr-notification__actions" v-if="!hideActions">
        <div class="rr-notification__decline" @click.stop="decline">
          <button class="link link_light text-sm">{{ declineText || 'Не интересно' }}</button>
        </div>
        <div class="rr-notification__confirm">
          <Button
            size="sm"
            :variant="confirmType || 'primary'"
            :text="confirmText || 'Подробнее'"
            :iconLeft="confirmIcon || ''"
            :iconRight="confirmIconRight || ''"
            @click="confirm"
            mobile
          ></Button>
        </div>
      </div>
    </div>
  </div>
</template>


<script lang="js">
export default {
  name: 'RrNotification',
  props: {
    title: String,
    text: String,
    img: String,
    discountText: String,
    declineText: String,
    confirmText: String,
    confirmType: String,
    confirmIcon: String,
    confirmIconRight: String,
    hideActions: Boolean,
    btnPrefix: {
      type: String,
      default: 'rr-',
    },
  },
  data() {
    return {
      isOpen: true,
    };
  },
  methods: {
    open() {
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
      this.$emit('closed');
    },
    dismiss() {
      this.$emit('dismiss');
      this.close();
    },
    decline() {
      this.$emit('decline');
      this.close();
    },
    confirm() {
      this.$emit('confirm');
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@rr-component-library/common/src/scss/vars.scss";
@import "~@rr-component-library/common/src/scss/mixins.scss";
@import "@/scss/colors.scss";

@mixin base-sm-shadow {
  background-color: $color-white;
  border-radius: 16px;
  box-shadow: $elevation-deth-16;
}

.rr-notification {
  display: inline-block;
  vertical-align: top;
  @include base-sm-shadow;
  padding: 12px 16px;
  width: 100%;
  position: relative;
  text-align: left;
  pointer-events: auto;

  @media(min-width: breakpoint(md)) {
    max-width: 360px;
    min-width: 360px;
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    margin: 8px;
  }

  &__body {
    display: flex;
    align-items: flex-start;
  }

  &__img {
    width: 48px;
    flex: 0 0 auto;
    margin-right: 16px;

    @media (min-width: breakpoint(md)) {
      width: 64px;
    }

    img {
      display: block;
      width: 100%;
    }
  }

  &__discount {
    position: absolute;
    top: 12px;
    left: 0;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include text-xs;
    color: $color-white;
    font-weight: $font-weight-semibold;
    padding: 0px 8px 0px 6px;
    border-radius: 0px 8px 8px 0px;
    background: $color-danger;
  }

  &__content {
    flex: 1 1 100%;
    min-width: 0;
    align-self: center;
  }

  &__title {
    @include text-md;
    color: $color-black-85;
    font-weight: $font-weight-semibold;

    margin-bottom: 4px;
    margin-right: 32px;
  }

  &__text {
    @include text-sm;
    color: $color-black-85;

    ::v-deep b {
      font-weight: $font-weight-semibold;
    }

    ::v-deep span {
      @include text-md;
      font-weight: $font-weight-semibold;
      color: var(--color-primary);
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid $color-black-15;
    padding-top: 12px;
    margin-top: 16px;
  }

  &__confirm ::v-deep .rr-button {
    border-radius: 8px;
  }
}

.link {
  flex: 0 0 auto;
  font-family: inherit;
  font-weight: $font-weight-semibold;
  border: none;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  background: none;
  box-shadow: none;
  position: relative;
  margin: 0;
  padding: 0;
  text-decoration: none;
  color: $color-primary;
  transition: $transition-default color, $transition-default opacity;

  &_light {
    color: $color-black-45;
  }

  &:hover, &:focus {
    color: $color-primary-6;
  }

  &_white {
    color: $color-white-85;

    &:hover, &:focus {
      color: $color-white;
    }
  }

  &_inherit {
    color: inherit;
    opacity: 0.85;

    &:hover, &:focus {
      color: inherit;
      opacity: 1;
    }
  }
}

.text-sm {
  font-size: 14px;
  line-height: 22/14*1em;
}
</style>
