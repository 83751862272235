import axios, { AxiosInstance } from "axios";


export class KktApi {
  
  protected readonly api: AxiosInstance = axios.create({
    responseType: "json",
    baseURL: "/public/kkt/api/v1",
    headers: {
      "Content-Type": "application/json"
    }
  });

  public async getReceipts(payload: { invoiceId: string, token?: string }): Promise<{ status: number, data: any }> {
    
    const headers: any = {};
    if (payload.token) headers['Authorization'] = `Bearer ${payload.token}`;

    const result: { status: number, data: any } = await this.api
      .get<any>(`/receipts`, { params: {
          invoiceId: payload.invoiceId,
        },
        headers,
      })
      .then(response => {
        return { status: response.status, data: response.data };
      })
      .catch(error => {
        if (error.response === undefined) {
          // on network error
          return { status: 1001, data: null };
        } else {
          // on other errors
          return { status: error.response.status, data: null };
        }
      });
    
    return result;
  }

  public async getReceiptById(payload: { receiptId: string, invoiceId: string, token?: string }): Promise<{ status: number, data: any }> {
    
    const headers: any = {};
    if (payload.token) headers['Authorization'] = `Bearer ${payload.token}`;

    const result: { status: number, data: any } = await this.api
      .get<any>(`/receipts/${payload.receiptId}`, { params: {
          invoiceId: payload.invoiceId,
        },
        headers,        
      })
      .then(response => {
        return { status: response.status, data: response.data };
      })
      .catch(error => {
        if (error.response === undefined) {
          // on network error
          return { status: 1001, data: null };
        } else {
          // on other errors
          return { status: error.response.status, data: null };
        }
      });
    
    return result;
  }

  public async downloadReceipts(payload: { invoiceId: string, fileType: string, token?: string }): Promise<{ status: number, data: any }> {
    
    const headers: any = {};
    if (payload.token) headers['Authorization'] = `Bearer ${payload.token}`;

    const api: AxiosInstance = axios.create({
      responseType: "blob",
      baseURL: "/public/kkt/api/v1",
      headers: {
        "Cache-Control": "no-cache",
        "Pragma": "no-cache",
      }
    });

    const result: { status: number, data: any } = await api
      .get<any>(`/receipts/download`, { params: {
          invoiceId: payload.invoiceId,
          fileType: payload.fileType || 'html',
        },
        headers,
      })
      .then(response => {
        return { status: response.status, data: response.data };
      })
      .catch(error => {
        if (error.response === undefined) {
          // on network error
          return { status: 1001, data: null };
        } else {
          // on other errors
          return { status: error.response.status, data: null };
        }
      });
    
    return result;
  }

}
