// tslint:disable-next-line:typedef
const routes = [
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: "home" */ "@/views/home/home.vue")
  },
  {
    path: "/event/:id",
    name: "event",
    component: () => import(/* webpackChunkName: "event" */ "@/views/event/event.vue")
  },
  {
    path: "/event/:id/photos",
    name: "event-photos",
    component: () => import(/* webpackChunkName: "event" */ "@/views/event/event-photos.vue")
  },
  {
    path: "/search",
    name: "search",
    component: () => import(/* webpackChunkName: "search" */ "@/views/search/search.vue")
  },
  {
    path: "/search/:id/:number",
    name: "search-from-url",
    component: () => import(/* webpackChunkName: "search" */ "@/views/search/search.vue")
  },
  {
    path: "/cart",
    name: "cart",
    component: () => import(/* webpackChunkName: "cart" */ "@/views/cart/cart.vue")
  },
  {
    path: "/order",
    name: "order",
    component: () => import(/* webpackChunkName: "order" */ "@/views/order/order.vue")
  },
  {
    path: "/order/:id",
    name: "order-paid",
    component: () => import(/* webpackChunkName: "order" */ "@/components/order-paid/order-paid.vue")
  },
  {
    path: "/preorder/:id",
    name: "preorder-paid",
    component: () => import(/* webpackChunkName: "order" */ "@/components/order-paid/order-paid.vue")
  },
  {
    path: "/contacts",
    name: "contacts",
    component: () => import(/* webpackChunkName: "contacts" */ "@/views/contacts/contacts.vue")
  },
  {
    path: "/for-organizers",
    name: "organizers",
    component: () => import(/* webpackChunkName: "contacts" */ "@/views/organizers/organizers.vue")
  },
  {
    path: "/for-photographers",
    name: "photographers",
    component: () => import(/* webpackChunkName: "contacts" */ "@/views/photographers/photographers.vue")
  },
  {
    path: "/for-business",
    name: "business",
    component: () => import(/* webpackChunkName: "contacts" */ "@/views/business/business.vue")
  },
  {
    path: "/my-account",
    name: "my-account",
    component: () => import(/* webpackChunkName: "my-account" */ "@/views/my-account/my-account.vue"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "orders",
        name: "my-orders",
        alias: "/my-account",
        component: () => import(/* webpackChunkName: "my-account" */ "@/components/my-account/orders/orders.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "order/:id",
        name: "my-order",
        component: () => import(/* webpackChunkName: "my-account" */ "@/components/my-account/order/order.vue"),
        meta: {
          requiresAuth: true,
        },
      },
    ]
  },
  { 
    path: '/*', 
    redirect: { name: "home" }
  },
];

export default routes;