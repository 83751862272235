export const MenuLang = [
  {
    id: "ru",
    text: "Русский",
  },
  {
    id: "en",
    text: "English",
  },
  {
    id: "es",
    text: "Español",
  },
];