<template>
  <div
    v-show="snackbar"
    class="error-snackbar"
    :class="$root.isMobile ? '' : 'error-snackbar--large'"
  >
    <div class="error-snackbar__container">
      <div class="container__text">
        <div class="text__message">{{ lastError }}</div>
        <div style="margin-top: 16px; margin-left: -12px;">
          <Button 
            @click.stop="onClose"
            variant="clear" 
            view="light"
            size="sm"
          >
            <slot>
              <span class="rr-button__text">{{ $t('close') }}</span>
            </slot>
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>


<script lang="js">
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'ErrorSnackbar',
  data() {
    return {
      snackbar: false,
    };
  },
  computed: {
    ...mapGetters('ErrorsState', ['lastError', 'errorsExist']),
  },
  watch: {
    errorsExist() {
      if (this.errorsExist) {
        this.updateErrorsTask();
      }
    },
  },
  methods: {
    ...mapActions('ErrorsState', ['getlastError']),
    updateErrorsTask() {
      if (this.errorsExist === true) {
        this.getlastError();
        this.snackbar = true;
        setTimeout(this.updateErrorsTask.bind(this), 7000);
      } else {
        this.snackbar = false;
      }
    },
    onClose() {
      this.snackbar = false;
      this.$emit('closed');
    },
  },
};
</script>


<style lang="scss" scoped>
  @import "./error-snackbar";
</style>