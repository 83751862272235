<template>
  <div class="footer">
    
    <div class="footer__container" @click.stop="onLangBlur()">
      <div class="footer__buttons">
        <div v-if="showSocials" class="buttons__socials">
          <div v-if="true" class="socials__icon" >
            <a href="https://vk.com/mysport.photo" target="_blank" class="socials__link">
              <base-icon width="20" height="20" viewBox="0 0 18 18">
                <icon-vkontakte opacity="0.65" />
              </base-icon>
            </a>
          </div>
          <div v-if="false" class="socials__icon" >
            <a href="" target="_blank" class="socials__link">
              <base-icon width="20" height="20" viewBox="0 0 20 20">
                <icon-youtube />
              </base-icon>
            </a>
          </div>
        </div>
        <div v-if="$store.state.showLang" class="buttons__localization">
          <Button 
            @click.stop="onLangClick()"
            @blur="onLangBlur()"
            @hover="showLangDropdown = true"
            variant="ghost"
            view="light"
            size="md"
            class="localization__lang"
            :class="!$root.isMobile ? 'localization__lang--large' : ''"
          >
            <slot name="before">
              <span class="rr-button__icon">
                <base-icon width="16" height="16">
                  <icon-global fill="currentColor" />
                </base-icon>
              </span>
            </slot>
            <slot>
              <span class="rr-button__text">{{ langTitle }}</span>
              <div v-if="$store.state.showLang">
                <a href="#" @click.prevent class="lang__menu-item">
                  <div class="menu-item__submenu" style="bottom: 52px;" v-if="$root.isMobile || $root.isTablet" :style="{'display': showLangDropdown ? 'block' : 'none'}">
                    <div
                      v-for="item in menuLang"
                      :key="item.id"
                      class="submenu__item"
                      @click.prevent="onLangSelect(item.id)"
                    >
                      <div class="submenu__image">
                        <img :src="'/img/lang/' + item.id + '.svg'" :alt="item.id" />
                      </div>
                      <div class="submenu__text" @click.prevent="onLangSelect(item.id)">
                        {{ item.text }}
                      </div>
                    </div>
                  </div>
                  <div style="position: absolute; bottom: 40px; width: max-content; min-width: 100%; z-index: 3; padding-bottom: 12px;">
                    <div class="menu-item__submenu" v-if="!$root.isMobile && !$root.isTablet">
                      <div
                        v-for="item in menuLang"
                        :key="item.id"
                        class="submenu__item"
                        @click.prevent="onLangSelect(item.id)"
                      >
                        <div class="submenu__image">
                          <img :src="'/img/lang/' + item.id + '.svg'" :alt="item.id" />
                        </div>
                        <div class="submenu__text" @click.prevent="onLangSelect(item.id)">
                          {{ item.text }}
                        </div>
                      </div>                  
                    </div>
                  </div>
                </a>
              </div>
            </slot>
          </Button>
        </div>
      </div>
      <div v-if="showCopyright" class="footer__copyright">
        <span v-html="copyrightText"></span>
        <span v-if="theme === 'IronStar'"> | <a :href="supportLink" class="copyright__link" v-html="$t('footer/support')"></a></span>
        <span> | </span>
        <a href="/policy.pdf" target="_blank" class="copyright__link">Конфиденциальность</a>
      </div>
      <div v-if="showSupport" class="footer__copyright">
        <a :href="supportLink" class="copyright__link" v-html="$t('footer/support')"></a>
      </div>
    </div>

  </div>
</template>


<script lang="js">
import { mapGetters, mapActions } from 'vuex';
import BaseIcon from "@/components/base-icon/base-icon.vue";
import IconVkontakte from "@/components/icons/socials/icon-vkontakte.vue";
import IconYoutube from "@/components/icons/socials/icon-youtube.vue";
import IconGlobal from "@/components/icons/icon-global.vue";
import { MenuLang } from "@/menu-lang";

export default {
  name: "Footer",
  components: {
    BaseIcon,
    IconVkontakte,
    IconYoutube,
    IconGlobal,
  },
  data() {
    return {
      menuLang: MenuLang,
      langTitle_: "",
      showLangDropdown: false,
      supportLink: "mailto:support@mysport.photo?subject=[Support]",
    };
  },
  computed: {
    ...mapGetters('SettingsState', ['theme', 'lang', 'languages', 'useAppBarDefault']),
    langTitle() {
      return this.langTitle_;
    },
    showSocials() {
      const showFor = [''];
      return showFor.includes(this.theme) && this.lang === 'ru';
    },
    showCopyright() {
      const showFor = ['', 'IronStar', 'MyEventPhoto'];
      return showFor.includes(this.theme) && this.lang === 'ru';
    },
    copyrightText() {
      const year = new Date().getFullYear();
      const showDefaultTextFor = ['', 'MyEventPhoto'];
      return showDefaultTextFor.includes(this.theme) ? `© ${year}, ООО «Софтпродукт» (ОГРН&nbsp;1227600003487)` : `© ${year}, ООО «Архитектура Спорта» (ОГРН&nbsp;1167746111191, ИНН&nbsp;9717014483)`;
    },
    showSupport() {
      const hideSupportFor = ['', 'IronStar', 'MyEventPhoto'];
      return !hideSupportFor.includes(this.theme) || this.lang !== 'ru';
    },
  },
  watch: {
    lang() {
      this.onLangChanged();
    },
  },
  methods: {
    ...mapActions('SettingsState', ['setLang']),
    onLangChanged() {
      const found = this.menuLang.filter((i) => i.id === this.lang);
      if (found.length == 0) this.langTitle_ = '';
      else this.langTitle_ = found[0].text;
    },
    onLangClick() {
      const vm = this;
      setTimeout(() => { vm.showLangDropdown = !vm.showLangDropdown; return false; }, 250);
    },
    onLangBlur() {
      const vm = this;
      setTimeout(() => { vm.showLangDropdown = false; }, 250);
    },
    onLangSelect(lang) {
      if (!this.$root.isMobile && !this.$root.isTablet) {
        this.showLangDropdown = false;    
        setTimeout(() => { this.showLangDropdown = true; }, 100);
      }
      
      this.setLang(lang);
    },
    setLanguagesAvailable() {
      const langs = this.languages;
      if (langs) {
        const result = [];
        for (let i = 0; i < MenuLang.length; i += 1) {
          if (langs.includes(MenuLang[i].id)) {
            result.push(MenuLang[i]);
          }
        }
        this.menuLang = result;
        return;
      }
      this.menuLang = MenuLang;
    },
  },
  mounted() {
    if (!this.$root.isMobile && !this.$root.isTablet) {
      this.showLangDropdown = true;
    }

    this.setLanguagesAvailable();

    this.onLangChanged();
  }
}
</script>


<style lang="scss" scoped>
  @import "./footer";
</style>