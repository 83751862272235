/* eslint no-shadow: ["error", { "allow": ["state"] }] */

const state = {
  errors: [],
  lastError: '',
};

const mutations = {
  addError(state, value) {
    if (value.err.name != "NavigationDuplicated") {
      state.errors.push(value);
    }
  },
  getlastError(state) {
    const error = state.errors.shift();
    const err = error?.err;
    const message = err?.message || err?.name || err?.stack || "";
    state.lastError = message;
  }
};

const getters = {
  errorsExist: (state) => state.errors.length > 0,
  lastError: (state) => state.lastError,
};

const actions = {
  addError({ commit }, payload) {
    commit('addError', payload);
  },
  getlastError({ commit }) {
    commit('getlastError');
  },
};

export default {
  name: 'ErrorsState',
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
