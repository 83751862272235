import axios, { AxiosInstance } from "axios";

import TENANT from "@/tenant.config";


export class PhotoServiceApi {
  
  protected readonly api: AxiosInstance = axios.create({
    responseType: "json",
    baseURL: "/public/photo-service/api/v1",
    headers: {
      "Content-Type": "application/json"
    }
  });

  public async getEvents(payload: { 
    externalEventId?: string, 
    search?: string, 
    pagination?: any, 
    organizerId?: string, 
    lang?: string,
  }): Promise<{ status: number, data: any }> {

    const w: any = window;
    const result: { status: number, data: any } = await this.api
      .get<any>(`/events`, { params: {
          tenantId: w.MYSPORT_PHOTO_APP_SETTINGS?.tenantId || TENANT.tenantId,
          externalEventId: payload.externalEventId || "",
          organizerId: payload.organizerId || "",
          searchString: payload.search || "",
          ...payload.pagination,
        },
        headers: {
          "Accept-Language": payload.lang || "ru",
        },
      })
      .then(response => {
        return { status: response.status, data: response.data };
      })
      .catch(error => {
        if (error.response === undefined) {
          // on network error
          return { status: 1001, data: null };
        } else {
          // on other errors
          return { status: error.response.status, data: null };
        }
      });
    
    return result;
  }

  public async getEventById(eventId: string, lang?: string): Promise<{ status: number, data: any }> {

    const result: { status: number, data: any } = await this.api
      .get<any>(`/events/${eventId}`, { 
        headers: {
          "Accept-Language": lang || "ru",
        },
      })
      .then(response => {
        return { status: response.status, data: response.data };
      })
      .catch(error => {
        if (error.response === undefined) {
          // on network error
          return { status: 1001, data: null };
        } else {
          // on other errors
          return { status: error.response.status, data: null };
        }
      });
    
    return result;
    
  }

  public async searchByTag(payload: {
    eventId: string, pagination: any, token?: string, additional?: boolean,
  }): Promise<{ status: number, data: any }> {

    const params = {
      albumId: payload.eventId,
    };

    const headers: any = {};
    if (payload.token) headers['Authorization'] = `Bearer ${payload.token}`;

    const result: { status: number, data: any } = await this.api
      .get<any>(`/photos`, { params: {
          ...params,
          ...payload.pagination,
        },
        headers,
      })
      .then(response => {
        return {
          status: response.status,
          data: { result: response.data, isAdditional: payload.additional === true }
        };
      })
      .catch(error => {
        if (error.response === undefined) {
          // on network error
          return { status: 1001, data: null };
        } else {
          // on other errors
          return { status: error.response.status, data: null };
        }
      });
    
    return result;
  }

  public async setItIsMe(payload: {
    photoId: string, itIsMe: boolean, photoTagId: string, name: string, value: string,
  }): Promise<{ status: number, data: any }> {
    
    const result: { status: number, data: any } = await this.api
      .post<any>(`/photos/${payload.photoId}/itsme`, {
        itIsMe: payload.itIsMe,
        tag: {
          photoTagId: payload.photoTagId,
          name: payload.name,
          value: payload.value,
        },
      })
      .then(response => {
        return { status: response.status, data: response.data };
      })
      .catch(error => {
        if (error.response === undefined) {
          // on network error
          return { status: 1001, data: null };
        } else {
          // on other errors
          return { status: error.response.status, data: null };
        }
      });
    
    return result;
  }
  
  public async searchByTagAll(payload: { eventId: string, token?: string }): 
    Promise<{ status: number, data: any }> {
    
    let temp: any[] = [];
    let t: any = null;
    let offset: number = 0;

    do {

      t = await this.searchByTag({
        eventId: payload.eventId,
        pagination: { offset: offset, count: 100 },
        token: payload.token,
      })
      
      if (t.status != 200) {
        break;
      }

      if (!t.data || !t.data.result) {
        break;
      }

      temp = temp.concat(...t.data.result.values);

      offset += 100;

    } while (t.data.result.count > 0 && temp.length < t.data.result.total)

    const result: { status: number, data: any } = { status: t.status, data: temp };
    
    return result;
  }
}
