import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";

import { ArchiveApi } from "@/services/api";

const archiveApi: ArchiveApi = new ArchiveApi();


@Module({
  name: "ArchiveState",
  namespaced: true
})
export class ArchiveState extends VuexModule {

  private archive_: any = null;
  private eventArchive_: any = null;
  private archiveError_: boolean = false;
  private file_: any = null;

  get archive(): any {
    return this.archive_;
  }

  get eventArchive(): any {
    return this.eventArchive_;
  }

  get archiveError(): boolean {
    return this.archiveError_;
  }

  get isArchiveReady(): boolean {
    if (!this.archive_) return false;
    return this.archive_.state === "Published";
  }

  get isEventArchiveReady(): boolean {
    if (!this.eventArchive_) return false;
    return this.eventArchive_.state === "Published";
  }

  get file(): File | null {
    return this.file_;
  }

  @Action
  async createArchive(payload: any): Promise<any> {
    const result: any = await archiveApi.createArchive(payload);
    if (result.status != 201) {
      this.context.commit('saveArchiveError', true);
    } else {
      this.context.commit('saveArchiveError', false);
    }
    return result;
  }

  @Action({
    commit: "saveArchive"
  })
  async getArchive(payload: any): Promise<any> {
    const result: any = await archiveApi.getArchive(payload);
    return result;
  }

  @Action({
    commit: "saveEventArchive"
  })
  async getEventArchive(payload: any): Promise<any> {
    const result: any = await archiveApi.getArchive(payload);
    return result;
  }

  @Action({
    commit: "saveFile"
  })
  async downloadFile(payload: any): Promise<any> {
    const result: any = await archiveApi.downloadFile(payload);
    return result;
  }

  @Mutation
  saveArchiveError(payload: any): void {
    this.archiveError_ = payload;
  }

  @Mutation
  saveArchive(payload: { status: number, data: any }): void {
    if (!payload.data) return;
    this.archive_ = payload.data || null;
  }

  @Mutation
  saveEventArchive(payload: { status: number, data: any }): void {
    if (!payload.data) return;
    this.eventArchive_ = payload.data || null;
  }

  @Mutation
  saveFile(payload: { status: number, data: any }): void {
    if (!payload.data) {
      this.file_ = null;
      return;
    }
    this.file_ = payload.data || null;
  }

}
