







import Vue from "vue";

import Component from "vue-class-component";

@Component({
  name: "IconClose",
  props: {
    fill: {
      type: String,
      default: "white"
    },
    opacity: {
      type: String,
      default: "0.85"
    }
  },
})
export default class IconClose extends Vue {
}
