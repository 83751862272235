import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import Cookies from "js-cookie";
import Common from "@/plugins/common";

import { CartApi } from "@/services/api";

const cartApi: CartApi = new CartApi();


@Module({
  name: "CartState",
  namespaced: true
})
export class CartState extends VuexModule {

  private cartId_: string = "";
  private cart_: any = null;
  private cartError_: boolean = false;
  private cartErrorData_: any = null;
  private customerError_: boolean = false;
  private status_: number = 0;

  get responseStatus(): any {
    return this.status_;
  }

  get cart(): any {
    return this.cart_;
  }

  get cartId(): string {
    return this.cartId_;
  }

  get cartState(): string {
    if (!this.cart_) return "";
    if (!this.cart_.state) return "";
    return this.cart_.state;
  }

  get isCartError(): boolean {
    return this.cartError_;
  }

  get cartErrorData(): any {
    return this.cartErrorData_;
  }

  get currencyId(): number {
    if (!this.cart_) return 0;
    if (!this.cart_.currencyId) return 0;
    return this.cart_.currencyId;
  }

  get currencyCode(): string {
    return Common.getCurrencyCode(this.currencyId.toString());
  }

  get amount(): number {
    if (!this.cart_) return 0;
    if (!this.cart_.amount) return 0;
    return this.cart_.amount;
  }

  get totalAmount(): number {
    if (!this.cart_) return 0;
    if (!this.cart_.totalAmount) return 0;
    return this.cart_.totalAmount;
  }

  get customer(): any {
    if (!this.cart_) return undefined;
    if (!this.cart_.customer) return undefined;
    return this.cart_.customer;
  }

  get isCustomerError(): boolean {
    return this.customerError_;
  }

  get orderId(): string {
    if (!this.cart_) return "";
    if (!this.cart_.orderId) return "";
    return this.cart_.orderId;
  }

  get isCheckoutDone(): boolean {
    if (!this.cart_) return false;
    return this.cart_.orderId !== undefined;
  }

  get isUpdated(): boolean {
    if (!this.cart_) return false;
    return this.cart_.state === "Updated";
  }

  get isProcessed(): boolean {
    if (!this.cart_) return false;
    return this.cart_.state === "Processed";
  }

  get cartItems(): any[] {
    if (!this.cart_) return [];
    if (!this.cart_.items) return [];
    return this.cart_.items;
  }

  get promotionalCodes(): any[] {
    if (!this.cart_) return [];
    if (!this.cart_.promotionalCodes) return [];
    return this.cart_.promotionalCodes;
  }

  get itemsCount(): number {
    if (!this.cart_) return 0;
    if (!this.cart_.items) return 0;
    return this.cart_.items.length;
  }

  get authToken(): any {
    return this.context.rootGetters["SettingsState/authToken"];
  }

  @Action
  async createCart(payload: any): Promise<any> {
    const token: any = this.context.getters["authToken"];
    if (token) payload['token'] = token;

    const result = await cartApi.createCart(payload);
    if (result.status == 201) {
      this.context.commit('saveCartId', payload);
      this.context.commit('saveCartError', false);
    } 
    return result;
  }

  @Action
  async loadCartId(): Promise<any> {
    if (Cookies.get('cartId') !== undefined) {
      this.context.commit('saveCartId', { cartId: Cookies.get('cartId') });
    }
    else if (localStorage.cartId) {
      this.context.commit('saveCartId', { cartId: localStorage.cartId });
    }
  }

  @Action
  async getCart(payload: any): Promise<any> {
    const token: any = this.context.getters["authToken"];
    if (token) payload['token'] = token;

    const result = await cartApi.getCart(payload);
    if (result.status == 200) {
      this.context.commit('saveCartError', false);
      this.context.commit('saveCartData', result.data);
    } else {
      this.context.commit('saveCartError', true);
      this.context.commit('saveCartData', null);
    }
    return result;
  }

  @Action
  async addItem(payload: any): Promise<any> {
    const result: any = await cartApi.addItem(payload);
    if (result.status == 204) {
      this.context.commit('saveCartError', false);
    } else {
      this.context.commit('saveCartError', true);
      this.context.commit('saveStatus', result.status);
    }
    return result;
  }

  @Action
  async addItems(payload: any): Promise<any> {
    const token: any = this.context.getters["authToken"];
    if (token) payload['token'] = token;

    const result: any = await cartApi.addItems(payload);
    if (result.status == 204) {
      this.context.commit('saveCartError', false);
    } else {
      this.context.commit('saveCartError', true);
      this.context.commit('saveStatus', result.status);
    }
    return result;
  }

  @Action
  async addPromocode(payload: any): Promise<any> {
    const token: any = this.context.getters["authToken"];
    if (token) payload['token'] = token;

    const result: any = await cartApi.addPromocode(payload);
    if (result.status == 204) {
      this.context.commit('saveCartError', false);
    } else {
      this.context.commit('saveCartError', true);
      this.context.commit('saveCartErrorData', result.data);
    }
    return result;
  }

  @Action
  async removeItem(payload: any): Promise<any> {
    const token: any = this.context.getters["authToken"];
    if (token) payload['token'] = token;

    const result: any = await cartApi.removeItem(payload);
    this.context.commit('saveCartError', false);
    return result;
  }

  @Action
  async removeItemsById(payload: any): Promise<any> {
    const token: any = this.context.getters["authToken"];
    if (token) payload['token'] = token;

    const result: any = await cartApi.removeItemsById(payload);
    this.context.commit('saveCartError', false);
    return result;
  }

  @Action
  async removePromocode(payload: any): Promise<any> {
    const token: any = this.context.getters["authToken"];
    if (token) payload['token'] = token;

    const result: any = await cartApi.removePromocode(payload);
    this.context.commit('saveCartError', false);
    return result;
  }

  @Action
  async clearCart(payload: any): Promise<any> {
    const token: any = this.context.getters["authToken"];
    if (token) payload['token'] = token;

    const result: any = await cartApi.clearCart(payload);
    this.context.commit('saveCartError', false);
    return result;
  }

  @Action
  async removeCartIds(): Promise<void> {
    this.context.commit('removeCartId');
    this.context.commit('saveCartError', false);
  }

  @Action
  async removeCart(): Promise<void> {
    this.context.commit('removeCartData');
    this.context.commit('saveCartError', false);
  }

  @Action
  async pushEcommerceAdd() {
    const item = { ...this.cartItems[this.cartItems.length - 1] };

    const data = {
      products: [{
        id: item.shoppingCartItemId,
        name: item.product.title,
        price: item.originalPrice,
        category: item.product.productType,
        quantity: 1,
      }],
    };

    await this.pushEcommerce({ actionType: 'add', data });
  }

  @Action
  async pushEcommerceRemove(itemsIds: any[]) {
    const items = itemsIds.length === 0
      ? [ ...this.cartItems ]
      : this.cartItems.filter((o) => itemsIds.indexOf(o.shoppingCartItemId) !== -1);

    const data = {
      products: items.map((item) => ({
        id: item.shoppingCartItemId,
        name: item.product.title,
        category: item.product.productType,
        quantity: 1,
      })),
    };

    await this.pushEcommerce({ actionType: 'remove', data });
  }

  @Action
  async pushEcommerce(payload: { actionType: string, data: any }) {
    const ecommerceData = {
      ecommerce: {
        currencyCode: this.currencyCode,
        [payload.actionType]: payload.data,
      }
    };

    if (process.env.NODE_ENV === 'production') {
      const w: any = window;
      await w.dataLayer.push(ecommerceData);
    } else {
      console.log('pushEcommerceAction', ecommerceData);
    }
  }

  @Action
  async setCustomer(payload: any): Promise<any> {
    const token: any = this.context.getters["authToken"];
    if (token) payload['token'] = token;

    const result: any = await cartApi.setCustomer(payload);
    if (result.status != 204) {
      this.context.commit('saveCustomerError', true);
    } else {
      this.context.commit('saveCustomerError', false);
    }
    return result;
  }
  
  @Action
  async setCartAttributes(payload: any): Promise<any> {
    const token: any = this.context.getters["authToken"];
    if (token) payload['token'] = token;

    const data = payload;
    const isMySportUSA = this.context.rootGetters["SettingsState/isMySportUSA"]
    let theme = this.context.rootGetters["SettingsState/theme"];
    if (isMySportUSA) theme = "MySportUSA";
    else if (theme === '') theme = "MySport";
    data['attributes'] = [
      {
        key: "Language",
        value: this.context.rootGetters["SettingsState/lang"],
      },
      {
        key: "Theme",
        value: theme,
      },
    ];
    const result: any = await cartApi.setCartAttributes(data);
    if (result.status != 204) {
      this.context.commit('saveCartError', true);
    } else {
      this.context.commit('saveCartError', false);
    }
    return result;
  }

  @Action
  async checkout(payload: any): Promise<any> {
    const token: any = this.context.getters["authToken"];
    if (token) payload['token'] = token;

    const result: any = await cartApi.checkout(payload);
    return result;
  }

  @Mutation
  saveStatus(payload: number): void {
    this.status_ = payload;
  }

  @Mutation
  saveCartId(payload: any): void {
    if (!payload) return;
    
    this.cartId_ = payload.cartId;
    Cookies.set('cartId', payload.cartId, { expires: 365 });
    localStorage.cartId = payload.cartId;
  }

  @Mutation
  saveCartData(payload: any): void {
    if (!payload) {
      this.cart_ = null;
      return;
    }
    if (!payload.state) return;

    if (payload.state === "Deleted") {
      this.cartError_ = true;
      return;
    }

    this.cart_ = payload;
  }

  @Mutation
  removeCartId(): void {
    Cookies.remove('cartId');
    localStorage.removeItem('cartId');
    this.cartId_ = "";
  }

  @Mutation
  removeCartData(): void {
    Cookies.remove('cartId');
    localStorage.removeItem('cartId');
    this.cartId_ = "";
    this.cart_ = null;
  }

  @Mutation
  saveCartError(payload: any): void {
    this.cartError_ = payload;
  }

  @Mutation
  saveCartErrorData(payload: any): void {
    this.cartErrorData_ = payload;
  }

  @Mutation
  saveCustomerError(payload: any): void {
    this.customerError_ = payload;
  }

}
