









































import Component from "vue-class-component";
import { getModule } from "vuex-module-decorators";
import { Watch } from "vue-property-decorator";
import Dinero from "dinero.js";
import Moment from "moment";

import BaseComponent from "@/components/base-component.vue";
import NavigationDrawer from "@/components/navigation-drawer/navigation-drawer.vue";
import AppBarDefault from "@/components/app-bar-default/app-bar-default.vue";
import AppBarThemed from "@/components/app-bar-themed/app-bar-themed.vue";
import RrNotifications from '@/components/notifications/notifications.vue';
import ErrorSnackbar from "@/components/error-snackbar/error-snackbar.vue";
import Footer from "@/components/footer/footer.vue";
import { SettingsState } from "@/store/modules/settings";

@Component({
  name: "App",
  components: {
    NavigationDrawer,
    AppBarDefault,
    AppBarThemed,
    RrNotifications,
    ErrorSnackbar,
    Footer,
  },
})
export default class App extends BaseComponent {

  private readonly settingsState: SettingsState = getModule(SettingsState, this.$store);

  loadingDone: boolean = false;

  languages: string[] = ['ru', 'en'];
  sng: string[] = ['az', 'hy', 'be', 'kk', 'ky', 'ro', 'tg', 'uz', 'uk', 'tk'];

  iconsContext: any = require.context(
    '!svg-inline-loader?'
    + 'removeTags=true&removeSVGTagAttrs=true&removingTagAttrs=fill'
    + '!../src/assets/icons',
    false,
    /\w+\.svg$/i,
  );

  showNotifications: boolean = true;

  get theme(): any {
    return this.settingsState.theme;
  }

  get themeDone(): boolean {
    return this.settingsState.themeDone;
  }

  get tenantName(): any {
    return this.settingsState.tenantName;
  }

  get currentRoute(): any {
    return this.$route;
  }

  get currentRouteName(): string {
    return this.currentRoute ? this.currentRoute.name || '' : '';
  }

  get isBarHidden(): boolean {
    return (this.isModalActive && (this.smOnly || this.mdOnly))
      || (this.isAppBarHidden && (!this.smOnly || this.smOnly && this.$route.name !== 'search-from-url'));
  }

  get isAppBarHidden(): boolean {
    return this.settingsState.isAppBarHidden;
  }

  get isModalActive(): boolean {
    return this.settingsState.isModalActive;
  }

  get isLoadingEvents(): boolean {
    return this.settingsState.isLoadingEvents;
  }

  get isLoadingRoute(): boolean {
    return this.settingsState.isLoadingRoute;
  }

  get isMySportUSA(): boolean {
    return this.settingsState.isMySportUSA === true;
  }

  get useAppBarDefault(): boolean {
    return this.settingsState.useAppBarDefault === true;
  }

  get lang(): string {
    return this.settingsState.lang;
  }

  get isAuthenticated() {
    return this.settingsState.isAuthenticated;
  }

  get email() {
    return this.settingsState.email;
  }

  get showFooter(): boolean {
    return this.loadingDone && this.themeDone && !this.isLoadingEvents && 
      !this.isLoadingRoute && (this.currentRoute ? this.currentRoute.name : false) &&
      this.settingsState.isMySportUSA !== true;
  }

  get isContacts(): boolean {
    return this.currentRoute.name === 'contacts';
  }

  get isEnglishDefault(): boolean {
    const host: string = window.location.host;
    return (0 === host.indexOf("us.mysport.") || 0 === host.indexOf("mysportusa."));
  }

  async onSetUser() {
    if (this.isAuthenticated) {
      const email = this.email || '';
      if (email) await this.settingsState.setUser(email);
      return;
    }
    const email: any = this.$route.query.email || '';
    if (email) await this.settingsState.setUser(email);
  }

  onResize() {
    const root: any = this.$root;
    root.isMobile = window.innerWidth < 768;
    root.isTablet = window.innerWidth >= 768 && window.innerWidth < 1200;
    root.isDesktop = window.innerWidth >= 1200;
  }

  @Watch("themeDone")
  async onThemeDone(): Promise<void> {
    await this.settingsState.loadImages(this.theme);
    await this.onSetUser();
    this.onResize();
  }

  @Watch("isLoadingEvents")
  async onLoadingEvents(): Promise<void> {
    if (this.isLoadingEvents) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }

  @Watch("lang")
  async onLangChanged(): Promise<void> {
    await this.changeLocale();
    await this.settingsState.loadImages(this.theme);
  }

  @Watch("currentRouteName")
  async onRouteChanged(): Promise<void> {
    if (this.currentRouteName) {
      this.showNotifications = false;
      setTimeout(() => { this.showNotifications = true; }, 100);
    }
  }

  async detectBrowserLang(): Promise<string> {
    const detected: string = window.navigator.language ? window.navigator.language.slice(0, 2) : 'ru';
    if (detected !== 'ru') {
      if (this.languages.includes(detected)) {
        return detected;
      } else if (this.sng.includes(detected)) {
        return 'ru';
      } else {
        return 'en';
      }
    }
    return '';
  }

  async changeLocale(): Promise<void> {
    const lang: string = this.settingsState.lang || "ru";
    this.$root.$i18n.locale = lang;
    this.$vuetify.lang.current = lang;
    Moment.locale(lang);
    Dinero.globalLocale = lang;
  }

  async created(): Promise<void> {
    await this.settingsState.loadSettings();
    await this.settingsState.loadLang();
    if (this.settingsState.isMySportUSA || this.isEnglishDefault) {
      await this.settingsState.setLang('en');
      return;
    }
    if (this.settingsState.isUsedDefaultLang) {
      const lang: string = await this.detectBrowserLang();
      if (lang) await this.settingsState.setLang(lang);
    }
    if (this.settingsState.languages && this.settingsState.languages.length === 1) {
      this.$store.state.showLang = false;
    }
    await this.changeLocale();
  }

  async mounted(): Promise<void> {
    this.loadingDone = true;

    window.addEventListener('resize', () => {
      this.onResize();
    });
    this.onResize();

    setTimeout(() => {
      if (this.themeDone) {
        this.onSetUser();
      }
    }, 1000);
  }

}
