







import Vue from "vue";

import Component from "vue-class-component";

@Component({
  name: "IconGlobal",
  props: {
    fill: {
      type: String,
      default: "#010101"
    },
    opacity: {
      type: String,
      default: "0.85"
    }
  },
})
export default class IconGlobal extends Vue {
}
