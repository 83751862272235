import Vue from "vue";
import Vuetify from "vuetify/lib";
import "@/scss/main.scss";

Vue.use(Vuetify);

import ru from "vuetify/src/locale/ru";

export default new Vuetify({
  lang: {
    locales: { ru },
    current: "ru",
  },
  breakpoint: {
    thresholds: {
      xs: 0,
      sm: 719 + 5,
      md: 1024 + 5 + 16,
      lg: 1281 + 5 + 16,
    },
  },
  theme: { disable: true },
});
