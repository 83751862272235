







import Vue from "vue";

import Component from "vue-class-component";

@Component({
  name: "IconUser",
  props: {
    fill: {
      type: String,
      default: "var(--color-black)"
    },
    opacity: {
      type: String,
      default: "0.85"
    }
  }
})
export default class IconUser extends Vue {
}
