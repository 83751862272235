/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import { FramesApi } from '@/services/api';

const state = {
  image: null,
  tokenExpired: false,
  framesStatus: null,
};

const mutations = {
  setImage(state, value) {
    state.image = value;
  },
  setTokenExpired(state, value) {
    state.tokenExpired = value;
  },
  setFramesStatus(state, value) {
    state.framesStatus = value;
  },
};

const getters = {
  image: (state) => state.image,
  isTokenExpired: (state) => state.tokenExpired,
  framesStatus: (state) => state.framesStatus,
};

const actions = {
  previewFrame({ commit }, payload) {
    return FramesApi.previewFrame(payload)
      .then((response) => {
        commit('setFramesStatus', response.status);
        if (response.status === 200) {
          commit('setImage', response.data);
          commit('setTokenExpired', false);
        } else {
          commit('setImage', null);
          commit('setTokenExpired', false);
        }
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setFramesStatus', status || 0);
        commit('setTokenExpired', status === 401);
      });
  },
};

export default {
  name: 'FramesState',
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
