import axios, { AxiosInstance } from "axios";


export class CatalogApi {
  
  protected readonly api: AxiosInstance = axios.create({
    responseType: "json",
    baseURL: "/public/catalog-service/api/v1",
    headers: {
      "Content-Type": "application/json"
    }
  });

  public async getList(payload: { eventId: string, distance: string }): Promise<{ status: number, data: any }> {
    
    const result: { status: number, data: any } = await this.api
      .get<any>(`/catalog/products`, { params: {
        catalogId: payload.eventId,
        distance: payload.distance,
        state: "InCatalog",
        count: 100,
      }})
      .then(response => {
        return { status: response.status, data: response.data };
      })
      .catch(error => {
        if (error.response === undefined) {
          // on network error
          return { status: 1001, data: null };
        } else {
          // on other errors
          return { status: error.response.status, data: null };
        }
      });
    
    return result;
  }

  public async getById(payload: { productId: string }): Promise<{ status: number, data: any }> {
    
    const result: { status: number, data: any } = await this.api
      .get<any>(`/catalog/products/${ payload.productId }`)
      .then(response => {
        return { status: response.status, data: response.data };
      })
      .catch(error => {
        if (error.response === undefined) {
          // on network error
          return { status: 1001, data: null };
        } else {
          // on other errors
          return { status: error.response.status, data: null };
        }
      });
    
    return result;
  }

}
