<template>
  <div class="rr-notifications">
    <Transition
      name="notifications"
      tag="div"
      mode="out-in"
    >
      <div class="rr-notifications__list">
        <rr-notification
          v-for="item of showItems"
          :key="item.id"
          v-bind="item.props"
          @confirm="item.confirm && item.confirm($event)"
          @decline="item.decline && item.decline($event)"
          @dismiss="item.dismiss && item.dismiss($event)"
          @closed="remove(item.id)"
        />
      </div>
    </Transition>
  </div>
</template>


<script lang="js">
import RrNotification from "./notification.vue";

export default {
  name: 'RrNotifications',
  components: {
    RrNotification,
  },
  data() {
    return {
      id: 0,
      items: [],
    };
  },
  computed: {
    showItems() {
      if (!this.items[0]) return [];
      return this.$root.isMobile ? [this.items[0]] : this.items.slice(0, 2);
    },
  },
  methods: {
    addNotification(data) {
      const notification = data;
      this.id += 1;
      notification.id = this.id;
      this.items.push(notification);
    },
    remove(id) {
      this.items = this.items.filter((o) => o.id !== id);
    },
  },
  created() {
    this.$root.$addNotification = this.addNotification;
  },
};
</script>


<style lang="scss" scoped>
@import "~@rr-component-library/common/src/scss/mixins.scss";
@import "@/scss/colors.scss";

.rr-notifications {
  position: fixed;
  z-index: 4;
  top: 56px;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 1244px;
  height: 0;
  margin: 0 auto;
  padding: 8px;
  text-align: right;
  pointer-events: none;

  @media (min-width: breakpoint(md)) {
    top: 64px;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 24px;
  }

  &__list {
    display: flex;
    flex-direction: column;
  }

  ::v-deep .rr-button_primary {
    border-radius: 8px;
  }
}

.rr-notification {
  margin-left: auto;
  margin-bottom: 16px;
  transition: all 0.2s;
  transition-delay: 1s;
}

.rr-notification:nth-child(2) {
  display: none;

  @media (min-width: breakpoint(md)) {
    display: inline-block;
  }
}

.notifications-enter,
.notifications-leave-to {
  opacity: 0;
  transform: translateY(-16px);
}

.notifications-leave-active {
  transition-delay: 0s;
  z-index: -1;
}
</style>
