import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";

import { InvoicesApi } from "@/services/api";

const invoicesApi: InvoicesApi = new InvoicesApi();


@Module({
  name: "InvoicesState",
  namespaced: true
})
export class InvoicesState extends VuexModule {

  private payment_: any = {};
  private paymentTypes_: any[] = [];
  private invoicePayments_: any[] = [];
  private status_: number = 0;

  get responseStatus(): any {
    return this.status_;
  }

  get payUrl(): string {
    if (!this.payment_) return "";
    if (!this.payment_.payUrl) return "";
    return this.payment_.payUrl;
  }

  get paymentTypes(): any[] {
    return this.paymentTypes_;
  }

  get payments(): any[] {
    return this.invoicePayments_;
  }

  get lastPayment(): any {
    const payments = [...this.payments.filter((i: any) => i.paymentTypeId !== 'Discount')];
    if (payments.length === 0) return false;

    let latestIndex: number = 0;
    let latestDate: number = 0;
    try {
      latestDate = Date.parse(payments[0].createdOn);
    } catch(e) {
      latestDate = 0;
    }

    for (let i = 0; i < payments.length; i += 1) {
      let date: number = 0; 
      try {
        date = Date.parse(payments[i].createdOn);
      } catch(e) {
        continue;
      }
      
      if (date > latestDate) {
        latestIndex = i;
        latestDate = date;
      }
    }

    return payments[latestIndex];
  }

  get lastPaymentDeclined(): boolean {
    if (!this.lastPayment) return false;

    return this.lastPayment.state === 'Declined';
  }

  get lastPaymentMethod(): string {
    if (!this.lastPayment) return '';

    return this.lastPayment.paymentTypeId;
  }

  @Action({
    commit: "savePaymentTypes"
  })
  async getPaymentTypes(payload: any): Promise<any> {
    const result: any = await invoicesApi.getPaymentTypes(payload);
    return result;
  }

  @Action({
    commit: "savePayment"
  })
  async createPayment(payload: any): Promise<any> {
    const result: any = await invoicesApi.createPayment(payload);
    this.context.commit('saveStatus', result.status);
    return result;
  }

  @Action({
    commit: "saveInvoicePayments"
  })
  async getPayments(payload: any): Promise<any> {
    const result: any = await invoicesApi.getPayments(payload);
    return result;
  }

  @Mutation
  savePaymentTypes(payload: any): void {
    if (!payload.data) return;
    this.paymentTypes_ = payload.data || [];
  }

  @Mutation
  savePayment(payload: any): void {
    if (!payload.data) {
      this.payment_ = {};
      return;
    }
    this.payment_ = payload.data || {};
  }

  @Mutation
  saveInvoicePayments(payload: any): void {
    if (!payload.data) return;
    this.invoicePayments_ = payload.data || [];
  }

  @Mutation
  saveStatus(payload: number): void {
    this.status_ = payload;
  }
}
