import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";

import { OrdersApi } from "@/services/api";
import { PreorderApi } from "@/services/api";
import Common from "@/plugins/common";

const ordersApi: OrdersApi = new OrdersApi();
const preorderApi: PreorderApi = new PreorderApi();


@Module({
  name: "OrdersState",
  namespaced: true
})
export class OrdersState extends VuexModule {

  private order_: any = {};
  private orders_: any = {};
  private items_: any[] = [];
  private orderError_: boolean = false;
  private pendingOrders_: string[] = [];
  private status_: number = 0;

  get responseStatus(): any {
    return this.status_;
  }

  get order(): any {
    return this.order_;
  }

  get orders(): any {
    return this.orders_;
  }

  get isReadyForPayment(): boolean {
    if (!this.order_) return false;
    return this.order_.state === "AwaitingPayment";
  }

  get isCompleted(): boolean {
    if (!this.order_) return false;
    return this.order_.state === "Completed";
  }

  get isCanceled(): boolean {
    if (!this.order_) return false;
    return this.order_.state === "Canceled";
  }

  get isOrderError(): boolean {
    if (!this.order_) return false;
    return this.orderError_;
  }

  get currencyId(): string {
    if (!this.order_) return "";
    if (!this.order_.currencyId) return "";
    return this.order_.currencyId.toString();
  }

  get currencyCode(): string {
    return Common.getCurrencyCode(this.currencyId);
  }

  get invoiceId(): string {
    if (!this.order_) return "";
    if (!this.order_.invoiceId) return "";
    return this.order_.invoiceId;
  }

  get items(): any[] {
    return this.items_;
  }

  get pendingOrders(): string[] {
    return this.pendingOrders_;
  }

  get authToken(): any {
    return this.context.rootGetters["SettingsState/authToken"];
  }

  @Action({
    commit: "saveOrder"
  })
  async getOrder(payload: any): Promise<any> {
    const token: any = this.context.getters["authToken"];
    if (token) payload['token'] = token;

    const result: any = await ordersApi.getOrder(payload);
    this.context.commit('saveStatus', result.status);
    if (result.status != 200) {
      this.context.commit('saveOrderError', true);
    } else {
      this.context.commit('saveOrderError', false);
    }
    return result;
  }

  @Action({
    commit: "saveOrders"
  })
  async getOrders(payload: any): Promise<any> {
    const token: any = this.context.getters["authToken"];
    if (token) payload['token'] = token;

    const result: any = await ordersApi.getOrders(payload);
    this.context.commit('saveStatus', result.status);
    if (result.status != 200) {
      this.context.commit('saveOrderError', true);
    } else {
      this.context.commit('saveOrderError', false);
    }
    return result;
  }

  @Action({
    commit: "saveItems"
  })
  async getItems(payload: any): Promise<any> {
    const token: any = this.context.getters["authToken"];
    if (token) payload['token'] = token;

    const result: any = await ordersApi.getItems(payload);
    return result;
  }

  @Action({
    commit: "savePreorder"
  })
  async getPreorderItems(payload: any): Promise<any> {
    const token: any = this.context.getters["authToken"];
    if (token) payload['token'] = token;
    
    const result: any = await preorderApi.getPreorderDetails(payload);
    if (result.status != 200) {
      this.context.commit('saveOrderError', true);
    } else {
      this.context.commit('saveOrderError', false);
    }
    return result;
  }

  @Action
  async pushEcommercePurchase() {
    const items = this.items;

    const data = {
      actionField: {
        id: this.invoiceId,
        revenue: this.order.totalAmount,
      },
      products: items.map((item) => ({
        id: item.orderItemId,
        name: item.product.title,
        price: item.totalPrice,
        category: item.product.productType,
        quantity: 1,
      })),
    };

    await this.pushEcommerce({ actionType: 'purchase', data });
  }

  @Action
  async pushEcommerce(payload: { actionType: string, data: any }) {
    const ecommerceData = {
      ecommerce: {
        currencyCode: this.currencyCode,
        [payload.actionType]: payload.data,
      }
    };

    if (process.env.NODE_ENV === 'production') {
      const w: any = window;
      await w.dataLayer.push(ecommerceData);
    } else {
      console.log('pushEcommerceAction', ecommerceData);
    }
  }

  @Action({
    commit: 'savePendingOrders'
  })
  async getPendingOrders() {
    const str = localStorage.getItem('pendingOrders');

    if (!str) {
      return [];
    }

    try {
      return JSON.parse(str);
    } catch {
      return [];
    }
  }

  @Action
  async addPendingOrder(orderId: string) {
    if (!orderId) {
      return;
    }

    const orders: string[] = await this.getPendingOrders();
    let added = false;

    if (orders.indexOf(orderId) === -1) {
      orders.push(orderId);
      this.context.commit('savePendingOrders', orders);
      added = true;
    }
    return added;
  }

  @Action
  async deletePendingOrder(orderId: string) {
    if (!orderId) {
      return;
    }

    let orders: string[] = await this.getPendingOrders();
    let deleted = false;

    if (orders.indexOf(orderId) !== -1) {
      orders = orders.filter((o) => o !== orderId);
      this.context.commit('savePendingOrders', orders);
      deleted = true;
    }
    return deleted;
  }

  @Mutation
  saveStatus(payload: number): void {
    this.status_ = payload;
  }

  @Mutation
  saveOrder(payload: { status: number, data: any }): void {
    if (!payload.data) return;
    this.order_ = payload.data || {};
  }

  @Mutation
  saveOrders(payload: { status: number, data: any }): void {
    if (!payload.data) return;
    this.orders_ = payload.data || {};
  }

  @Mutation
  saveItems(payload: { status: number, data: any }): void {
    if (!payload.data) return;
    this.items_ = payload.data || [];
  }

  @Mutation
  savePreorder(payload: { status: number, data: any }): void {
    if (!payload.data) return;
    this.order_ = {
      catalogId: payload.data.catalogId || "",
      state: payload.data.state || "",
      customer: {
        email: payload.data.email || "",
      },
      number: payload.data.number || "",
    };
    this.items_ = payload.data.items || [];
  }

  @Mutation
  saveOrderError(payload: any): void {
    this.orderError_ = payload;
  }

  @Mutation
  savePendingOrders(orders: string[]) {
    if (!orders) return;
    this.pendingOrders_ = orders;
    if (orders.length) {
      localStorage.setItem('pendingOrders', JSON.stringify(orders));
    } else {
      localStorage.removeItem('pendingOrders');
    }
  }
}
