import axios from "axios";


const api = axios.create({
  responseType: "json",
  baseURL: "/public/frame-service/api/v1",
  headers: {
    "Content-Type": "application/json"
  }
});

class FramesApi {
  static async previewFrame(payload) {
    
    const headers = {};
    if (payload.token) headers['Authorization'] = `Bearer ${payload.token}`;

    const result = await api
      .get(`/photos`, { 
        params: payload.params,
        responseType: 'blob',
        headers,
      })
      .then(response => {
        return { status: response.status, data: response.data };
      })
      .catch(error => {
        if (error.response === undefined) {
          // on network error
          return { status: 1001, data: null };
        } else {
          // on other errors
          return { status: error.response.status, data: null };
        }
      });
    
    return result;
  }
}

export {
  FramesApi,
}
