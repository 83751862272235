export const MenuServices = [
  {
    name: 'Russia Running',
    text: 'appBar/menuServicesRR',
    link: 'https://russiarunning.com/',
  },
  {
    name: 'My Sport Photo',
    text: 'appBar/menuServicesMedia',
    link: '/',
    active: true,
  },
];